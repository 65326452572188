// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="App-logo">
          🌻
        </p>
        <div className="App-link">の</div>
      </header>
    </div>
  );
}

export default App;
